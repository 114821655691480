form h5{
    padding: 20px;
    margin-top: 50px;
  }
  
  .delete-photo{
    margin-top: 0px;
  }
  
  input[type=text]:not(.browser-default):focus:not([readonly]),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=password]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]){
    border-color: #ec407a;
    box-shadow: none;
  }
  
  
  .project-summary {
    ul{
        padding: 0;
      }
  }
  .action-btns-sec {
    button{
        margin: 0px 5px;
      }
  }
  .autoLabel-right+input, .autoLabel-right+textarea{
    padding-right: 10px !important
  }
  .autoLabel-right{
    left: auto !important;
    right: 0;
  }

  .input-field{
    .btns-area{
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
    }
    #project-image{
      width: 380px;
      max-width: 100%;
    }
  }

  .search-section{
    input{
      padding-left: 10px !important;
    }
    input::placeholder{
      color: rgba(51, 51, 51, 0.5);
    }
  }