
.users-list{
    .collection-item{
        display: inline-block;
        width: 100%;
        .user-summary{
            .user-photo{
                float: left;
                width: 10%;
                margin-right: 3%;
                img{
                    width: 100%;
                }
            }
        }
    }
}