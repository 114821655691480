
.left-text li{
    float: right;
  }
  .nav-wrapper.arDr {
    a{
      svg{
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  nav {
    z-index: 99;
    top: 0;
    left: 0;
    width: 15%;
    min-height: 100vh;
    
    .inner-nav-container{
        display: inline-block;
        width: 100%;
        padding: 0 10px;
        padding-top: 20px;

        .astro-logo{
            display: inline-block;
            width: 100%;
            @extend .tac;
            font-size: 3vw;
          }
          ul{
            display: inline-block;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            li{
                display: inline-block;
                width: 100%;
                line-height: 3em;
                border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                a{
                  svg{
                    margin-right: 10px;
                  }
                }
            }
          }
          ul.lang-btns{
            width: auto;
            padding-left: 10px;
            padding-top: 17px;
        
            li{
              line-height: 2em;
                border: none;
                width: auto;
            }
            .selected{
              button{
              color: #424242;
              background-color: #fff;
              }
            }
          }
          .user-area{
            display: inline-block;
            width: 100%;
            text-align: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.19);

              .user-photo{
                width: 9vw;
                height: 9vw;
                @extend .di;
                border-radius: 100%;
                line-height: 3.5em;
                font-size: 2.5em;
                overflow: hidden;
                img{
                  display: inline;
                  width: 100%;
                }
              }
          }
          .lang-title{
            font-size: 35px;
            text-align: center;
          }
      }
  }
  
  .lang-btns button{
    display: inline-block;
    border: none;
    margin: 0 3px;
    color: #fff;
    background: #424242;
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  
.main-section-holder{
    float: left;
    width: 85%;
    min-height: 100vh;
    padding: 0 2%;
    background: url(/images/mario-bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
}
.image-holder{
  img{
    max-width: 100%;
  }
}
.main-section-holder.sign-in{
  width: 100%;
}
.main-section-holder.home-holder{
  width: 100%;
}
.card {
  margin-top: 0;
  .card-image{
    max-height: 400px;
    overflow: hidden;
  }
}

.row-with-margin{
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.lang-btns.outer{
  width: auto;
  padding: 0 17px;
  
  li{
    line-height: 2em;
      border: none;
      width: auto;
      margin: 0 3px;
      span{
        border: 1px solid #000;
        border-radius: 3px;
        padding: 2px 4px;
        cursor: pointer;
      }
  }
  .selected{
    span{
      background-color: #424242;
      color: #fff;
    }
  }
}
.product-form{
  position: relative;
}
.loading-submit{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  padding-top: 40%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.66);
  img{
    max-width: 100%;
  }
}

.header-language{
  float: left;
  padding-top: 45px;
  .lang-btns{
    padding: 0;
    margin: 0;
    display: inline-block;

    button{
      background-color: #333333;
      border: none;
      border-radius: 7px 0px 7px 0px;
      outline: none;
      &:hover{
        opacity: 0.7;
      }
    }
    .selected{
      button{
        background-color: #17b6e8;
      }
    }
  }
  
}

.new-item{
  display: inline-block;
  width: 100%;
  color: #fff;
  margin-top: 11px;
  background: #333333;
  text-align: center;
  padding: 11px 0px;
  border-radius: 5px;
  font-weight: 700;

  &:hover{
    background-color:#17b6e8;
  }

  svg{
    display: inline-block;
    margin: 0 7px;
  }
}
.inner-nav-holder{
  display: inline-block;
  width: 100%;
}

.project-List{
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
}

.slogan{
    display: inline-block;
    width: 100%;
    text-align: center;
    border-top: 1px solid #231f201f;
    border-bottom: 1px solid #231f201f;
    font-size: 27px;
    padding: 35px 15px;
    margin: 0;
    background: #333;
    color: #fff;
}

.alsoRead{
  padding-top: 40px;
}