@media (max-width: 560px){
    .footer-title{
        p{
            text-align: center;
        }
        .contact-methods-icons{
            position: relative;
            display: inline-block;
            width: 100%;
            li{
                float: none !important;
            }
        }
    }
}
@media (max-width: 769px){
    .home-navbar:after{
        display: none;
    }
    .slider-section {
        min-height: 325px;
    }

    .collapse {
        padding-top: 45px;
        padding-right: 15px;
        padding-left: 15px;
        background: #fff;
        ul{
            display: inline-block;
            width: 100%;
            li{
                display: inline-block;
                width: 100%;
            }
        }
    }
    .inner-nav-holder{
        display: inline-block;
        width: 100%;
        height: 45px;
    }
    .navbar-brand{
        position: absolute;
        left: 0;
        top: 7px;
    }
    .header-language{
        position: absolute;
        right: 58px;
        top: 12px;
        margin-top: 0;
        padding-top: 0;
    }
    .product-page-holder {
        .card-image{
            padding-top: 40px;
            min-height: unset;
        }
        .card-content {
            .card-article {
                .article-holder {
                    .contact-methods {
                        .contact-methods-icons{
                            text-align: left;
                            li{
                                float: left;
                            }
                        }
                    }
                    .arDr{
                        .contact-methods-icons{
                            text-align: right;
                            li{
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .slick-list{
        .inner-slide{
            height: 275px;
            img{
                width: auto;
                height: 100%;
            }
            h2{
                font-size: 26px;
                line-height: 23px;
                bottom: 6%;
    
            }
        }
    }

}