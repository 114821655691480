.fullWidth{
    @extend .di;
    @extend .wd;
}
.di{
    display: inline-block;
}
.as{
    @extend .bs;
    @extend .ts;
    @extend .rs;
    @extend .ls;
}
.bs{
    bottom: 0;
}
.ts{
    top: 0;
}
.rs{
    right: 0;
}
.ls{
    left: 0;
}
.ps{
    position: static;
}
.pl{
    position: relative;
}
.pa{
    position: absolute;
}
.px{
    position: fixed;
}
.oh{
    overflow: hidden;
  }
  .fr{
    float: right;
  }
  .fl{
    float: left;
  }
  .wd{
    width:100%;
  }
  .wbg{
    background-color: #fff;
  }
  .arDr{
    direction: rtl;
    .col{
      float: right;
    }
    .contact-methods-icons {
      position: absolute;
      li{
        float: right;
      }
    }
    .footer-info{
      text-align: right;
      border-right: 3px solid #fff;
      border-left: none;
      padding-right: 19px;
    }
  }
  .tar{
    text-align: right !important;
  }
  
  .tal{
    text-align: left !important;
  }
  
  .tac{
    text-align: center !important;
  }
