.product-page-holder{
    font-family: "Tajawal", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    display: inline-block;
    width: 100%;
    .card{
        margin-bottom: 0;
    }
    .card-image{
        max-height: unset;
        min-height: 460px;
    }
    .card-content{
        padding: 0;
        .card-title{
            font-weight: 700;
            background: #17b6e8;
            color: #fff;
            font-size: 32px;
            padding: 10px 0;
        }
        .card-article{
            display: inline-block;
            width: 100%;
            padding: 14px 0;
            font-size: 20px;
            .article-holder{
                display: inline-block;
                width: 100%;
                .contact-methods{
                    display: inline-block;
                    width: 100%;
                    padding-top: 15px;
                    margin-top: 15px;
                    border-top: 1px solid #231f201f;
                    p{
                        font-size: 16px;
                    }
                    .contact-methods-icons{
                        position: static;
                        margin-bottom: 0;
                        li{
                            a{
                                color: #333333;
                                &:hover{
                                    color: #17b6e8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.blank-loading{
    display: inline-block;
    width: 100%;
    padding: 100px 0;
    text-align: center;
    img{
        max-width: 100%;
    }
}